import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { css } from '@emotion/core';
import getOr from 'lodash/fp/getOr';
import __ from 'lodash/fp/placeholder';
import Layout from '../../organisms/Layout/Layout';
import { renderPageSections } from '../renderPageSections';
import BubbleCtaSection from '../../organisms/BubbleCtaSection/BubbleCtaSection';
import {
  breakpoints,
  fontSizes,
  lineHeights,
  unitConverter as uc,
} from '../../../styles/base';
import useLocalize from '../../../hooks/useLocalize';

const PartnerDetailPage = ({
  data: rawData,
  location,
  pageContext: { locale },
}) => {
  if (!rawData) {
    return null;
  }
  const data = useLocalize(rawData, locale);

  const partnerDetailCss = css`
    p {
      font-size: ${fontSizes.sixteen};
      line-height: ${lineHeights.title};
    }

    .page-wrapper {
      max-width: calc(100% - ${uc('140px')});
      margin: auto;
    }

    @media (${breakpoints.smallDesktopMin}) and (${breakpoints.desktop}) {
      .slick-slide div {
        max-width: 100%;
      }

      .slick-dots ul,
      .slick-slide div section {
        max-width: calc(100% - ${uc('140px')});
        margin: auto;
      }
    }

    @media (min-width: ${uc('1081px')}) and (max-width: ${uc('1279px')}) {
      .slick-slide div {
        max-width: 94%;
      }

      .slick-dots ul,
      .slick-slide div section {
        max-width: calc(94% - ${uc('140px')});
        margin: auto;
      }
    }

    @media (${breakpoints.smallDesktopMax}) {
      section:first-of-type {
        margin-bottom: ${uc('50px')};
      }
    }

    @media (${breakpoints.largeTablet}) {
      section:nth-of-type(2) {
        margin-bottom: ${uc('50px')};
      }

      .slick-list .slick-slide {
        padding-top: ${uc('60px')};
      }
    }

    @media (${breakpoints.tablet}) {
      .slick-dots ul button {
        font-size: ${fontSizes.eighteen};
      }
    }

    @media (${breakpoints.mobile}) {
      .slick-dots ul button {
        font-size: ${fontSizes.fourteen};
      }

      .page-wrapper {
        max-width: 100%;
      }
    }
  `;

  const getObjectFromData = getOr({}, __, data);
  const getArrayFromData = getOr([], __, data);
  const route = getObjectFromData('page._rawRoute');

  return (
    <Layout
      locale={locale}
      menuColor="white"
      href={location.href}
      pageCss={partnerDetailCss}
      sanityId={data.page._id}
      {...route}
    >
      {renderPageSections(getArrayFromData('page._rawSections'))}
      <BubbleCtaSection {...getArrayFromData('page._rawBubbleCta')} />
    </Layout>
  );
};

PartnerDetailPage.propTypes = {
  data: PropTypes.shape({ page: {} }),
  location: PropTypes.shape({ href: PropTypes.string }),
  pageContext: PropTypes.shape({ locale: PropTypes.string }),
};

PartnerDetailPage.defaultProps = {
  data: {},
  location: { href: '' },
  pageContext: { locale: '' },
};

export const query = graphql`
  query($slug: String) {
    page: sanityPartnerDetailPage(route: { slug: { current: { eq: $slug } } }) {
      title: _rawTitle(resolveReferences: { maxDepth: 100 })
      _rawRoute(resolveReferences: { maxDepth: 100 })
      _rawBubbleCta(resolveReferences: { maxDepth: 10 })
      _rawSections(resolveReferences: { maxDepth: 10 })
      _id
    }
  }
`;

export default PartnerDetailPage;
